import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateExternalSurveyTask, generateUploadsTask, generateWebPlatformTask } from "../tasks";
import { WebPlatform } from "../types";

export const VCCP0225CollGroupIds = [
    "VCCP-437-0225-UK-TIKTOK-GOOD",
    "VCCP-438-0225-UK-TIKTOK-BAD",
    "VCCP-439-0225-UK-INSTAGRAM-GOOD",
    "VCCP-440-0225-UK-INSTAGRAM-BAD",
    "VCCP-441-0225-UK-YAHOO-GOOD",
    "VCCP-442-0225-UK-YAHOO-BAD"
] as const;

export type VCCP0225CollGroupId = typeof VCCP0225CollGroupIds[number];

type VCCP0225CollConfig = {
    groupId: VCCP0225CollGroupId,
    metaFieldNames: ReadonlyArray<string>
}

export const VCCP0225CollWebPlatformIds = [
    "VCCP-0225-UK-TIKTOK-GOOD",
    "VCCP-0225-UK-TIKTOK-BAD",
    "VCCP-0225-UK-INSTAGRAM-GOOD",
    "VCCP-0225-UK-INSTAGRAM-BAD",
    "VCCP-0225-UK-YAHOO-GOOD",
    "VCCP-0225-UK-YAHOO-BAD"
] as const;

export type VCCP0225CollWebPlatformId = typeof VCCP0225CollWebPlatformIds[number];

const getTaskLengthMinutes = (webPlatformId: VCCP0225CollWebPlatformId): string => {
    switch (webPlatformId) {
        case "VCCP-0225-UK-TIKTOK-GOOD":
        case "VCCP-0225-UK-TIKTOK-BAD":
        case "VCCP-0225-UK-INSTAGRAM-GOOD":
        case "VCCP-0225-UK-INSTAGRAM-BAD":
            return "10";
        
        case "VCCP-0225-UK-YAHOO-GOOD":
        case "VCCP-0225-UK-YAHOO-BAD":
            return "7";
        
        default:
            assertNever(webPlatformId);
    }
}

const getWebPlatformConfigUrl = (webPlatformId: VCCP0225CollWebPlatformId): string => {
    return `https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/VCCP-0225/${webPlatformId}.json`;
}

const getWebPlatformId = (groupId: VCCP0225CollGroupId): VCCP0225CollWebPlatformId => {
    switch (groupId) {
        case "VCCP-437-0225-UK-TIKTOK-GOOD":
            return "VCCP-0225-UK-TIKTOK-GOOD";

        case "VCCP-438-0225-UK-TIKTOK-BAD":
            return "VCCP-0225-UK-TIKTOK-BAD";

        case "VCCP-439-0225-UK-INSTAGRAM-GOOD":
            return "VCCP-0225-UK-INSTAGRAM-GOOD";

        case "VCCP-440-0225-UK-INSTAGRAM-BAD":
            return "VCCP-0225-UK-INSTAGRAM-BAD";

        case "VCCP-441-0225-UK-YAHOO-GOOD":
            return "VCCP-0225-UK-YAHOO-GOOD";

        case "VCCP-442-0225-UK-YAHOO-BAD":
            return "VCCP-0225-UK-YAHOO-BAD";
        
        default:
            assertNever(groupId);
    }
}

const getWebPlatform = (groupId: VCCP0225CollGroupId): WebPlatform => {
    switch (groupId) {
        case "VCCP-437-0225-UK-TIKTOK-GOOD":
        case "VCCP-438-0225-UK-TIKTOK-BAD":
            return "TikTok";
        
        case "VCCP-439-0225-UK-INSTAGRAM-GOOD":
        case "VCCP-440-0225-UK-INSTAGRAM-BAD":
            return "Instagram";
        
        case "VCCP-441-0225-UK-YAHOO-GOOD":
        case "VCCP-442-0225-UK-YAHOO-BAD":
            return "Yahoo";

        default:
            assertNever(groupId);
    }
}

const getSurveyUrl = (groupId: VCCP0225CollGroupId): string => {
    switch (groupId) {
        case "VCCP-437-0225-UK-TIKTOK-GOOD":
        case "VCCP-438-0225-UK-TIKTOK-BAD":
            return "https://survey.au1.qualtrics.com/jfe/form/SV_6KXhXZypbx1MnL8";
        
        case "VCCP-439-0225-UK-INSTAGRAM-GOOD":
        case "VCCP-440-0225-UK-INSTAGRAM-BAD":
            return "https://survey.au1.qualtrics.com/jfe/form/SV_beBdZiazm8LsJrE";
        
        case "VCCP-441-0225-UK-YAHOO-GOOD":
        case "VCCP-442-0225-UK-YAHOO-BAD":
            return "https://survey.au1.qualtrics.com/jfe/form/SV_b78m6ffAxtKNqcK";

        default:
            assertNever(groupId);
    }
}

export const addVCCP0225CollConfig = (
    config: VCCP0225CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const FINISH_MESSAGE = "Congratulations! You have now completed all required tasks and your data has been uploaded. Your incentive has been processed. Thank you for your time.";
    const langStrings = getLanguageStrings(AppLanguage.en);

    const webPlatformId = getWebPlatformId(config.groupId);
    const webPlatformTask = generateWebPlatformTask({
        groupId: config.groupId,
        platform: getWebPlatform(config.groupId),
        webPlatformConfigUrl: getWebPlatformConfigUrl(webPlatformId),
        webPlatformID: getWebPlatformId(config.groupId),
        langStrings: langStrings,
        taskLengthMinutes: getTaskLengthMinutes(webPlatformId),
        useWebPlatformIDForTaskID: true
    });

    const externalSurveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields: config.metaFieldNames,
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: getSurveyUrl(config.groupId)
    });

    const uploadTask = generateUploadsTask({
        config: {
            title: "Uploads"
        },
        taskId: `${config.groupId}-UPLOADS`
    });

    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('preventTaskProgress', false)
        .setFlag('finishMessageOverride', FINISH_MESSAGE)
        .addTask(webPlatformTask)
        .addTask(externalSurveyTask)
        .addTask(uploadTask)

    return builder;
}