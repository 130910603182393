import { GetLoginIdRequester, ValidateCintRespondentRequester } from 'loginid-api-toolkit'
import { loginIdSplitter } from 'loginid-api-toolkit/src/helpers'
import { generateCintConfig, generateMturkConfig } from 'respondent_config_generator/src/configGenerator'
import { RespondentCompletionCodeTask, RespondentConfig, RespondentConfigState } from 'respondent_config_generator/src/types'
import { ConfigDownloader, ConfigUploader } from '../APIs/s3'
import { CAIConfigKey } from '../configKey'
import { setupSuccess } from '../LandingPage/actions'
import { LandingPageConfigBuilder } from '../LandingPage/landingPageConfigBuilder'
import { LandingPageSupportEmail } from '../LandingPage/reducer'
import { isMturkParams, isPreview } from '../MTurk/mturkParams'
import { CAIParams, MTurkParams, parseQueryParams, QueryParamsRequestable } from '../queryParams'
import { AREMediaGroupId, configureAreMediaPage } from './ConfigDataStores/AREM'
import { AUSTGroupId, configureAUSTPage } from './ConfigDataStores/AUST'
import { C9AU1021GroupId, configureC9AU1021Page } from './ConfigDataStores/C9AU-1021'
import { configureMturkPage, isMturkGazeDesktopGroupId } from './ConfigDataStores/mturk'
import { configureODEU0422Page, ODEU0422CollGroupId } from './ConfigDataStores/ODEU-0422'
import { configureOMDGPage, OMDGGroupId } from './ConfigDataStores/OMDG'
import { configureREDC0122Page, REDC0122CollGroupId } from './ConfigDataStores/REDC-0122'
import { configureTEAD1222Page, TEAD1222CollGroupId } from "./ConfigDataStores/TEAD-1222";
import { configureTWIT1221Page, TWIT1221CollGroupId } from './ConfigDataStores/TWIT-1221'
import { configureYAHO0422Page, YAHO0422CollGroupId } from './ConfigDataStores/YAHO-0422'
import { configureSNAP0522Page, SNAP0522CollGroupId } from './ConfigDataStores/SNAP-0522'
import { configureADCO0622Page, ADCO0622CollGroupId } from './ConfigDataStores/ADCO-0622'
import { getLoginIdRequestParameters, requiresConfiguration } from './respondentConfig'
import { configureCRAA0822Page, CRAA0822GroupId } from './ConfigDataStores/CRAA-0822'
import { configureACAY0822Page, ACAY0822CollGroupId } from './ConfigDataStores/ACAY-0822'
import { configureVIAB0822Page, VIAB0822CintGroupId } from './ConfigDataStores/VIAB-0822'
import { configureOMNZPage, OMNZ1022CollGroupId } from './ConfigDataStores/OMNZ-1022'
import { configureOMMEPage, OMME1022CollGroupId } from './ConfigDataStores/OMME-1022'
import { configureNEAU1022Page, NEAU1022CollGroupId } from './ConfigDataStores/NEAU-1022'
import { configureTWIC1222Page, TWIC1222CollGroupId } from './ConfigDataStores/TWIC-1222'
import { configureTWIH0123Page, TWIH0123CollGroupId } from './ConfigDataStores/TWIH-0123'
import { configureSPOT0123Page, SPOT0123CollGroupId } from './ConfigDataStores/SPOT-0123'
import { configurePEPS0523Page, PEPS0523CollGroupId } from './ConfigDataStores/PEPS-0523'
import { configureGYGG0623Page, GYGG0623CollGroupId } from './ConfigDataStores/GYGG-0623'
import { configureSPIN0823Page, SPIN0823CollGroupId } from './ConfigDataStores/SPIN-0823'
import { configureSNAR0823Page, SNAR0823CollGroupId } from './ConfigDataStores/SNAR-0823'
import { configurePINT1123Page, PINT1123CollGroupId } from './ConfigDataStores/PINT-1123'
import { configureSKYN0823Page } from './ConfigDataStores/SKYN-0823'
import { configureRCTV0523Page } from './ConfigDataStores/RCTV-0523'
import { configureYAHD0823DesktopPage, configureYAHD0823MobilePage } from './ConfigDataStores/YAHD-0823'
import { YAHD0823DesktopGroupId, YAHD0823MobileGroupId } from 'respondent_config_generator/src/projects/YAHD-0823'
import { configureGYGG0124Page, GYGG0124CollGroupId } from "./ConfigDataStores/GYGG-0124";
import { configureSPAU0324Page, SPAU0324CollGroupId } from './ConfigDataStores/SPAU-0324'
import { configureALLW0424Page, ALLW0424CollGroupId } from "./ConfigDataStores/ALLW-0424";
import { configureSNEM0524Page, SNEM0524CollGroupId } from "./ConfigDataStores/SNEM-0524";
import { configureGCPL0624Page, GCPL0624CollGroupId } from "./ConfigDataStores/GCPL-0624";
import { configureGCPL1024Page, GCPL1024CollGroupId } from './ConfigDataStores/GCPL-1024'
import { configureGYGG1024Page } from './ConfigDataStores/GYGG-1024'
import { GYGG1024CollGroupId } from 'respondent_config_generator/src/projects/GYGG-1024'
import { OMAU1124CollGroupId } from 'respondent_config_generator/src/projects/OMAU-1124'
import { configureOMAU1124Page } from './ConfigDataStores/OMAU-1124'
import { VCCP0225CollGroupId } from 'respondent_config_generator/src/projects/VCCP-0225'
import { configureVCCP0225Page } from './ConfigDataStores/VCCP-0225'

// Actions
const setupInitialMturkState = (
    params: MTurkParams,
    landingPageBuilder: LandingPageConfigBuilder,
) => {
    if (isMturkGazeDesktopGroupId(params.G)) {
        landingPageBuilder.setSupportEmail(LandingPageSupportEmail.mturkDesktop)
    } else {
        landingPageBuilder.setSupportEmail(LandingPageSupportEmail.mturk)
    }

    if (isPreview(params)) {
        landingPageBuilder.setPreviewMode(true)
    }
};

const generateCaiConfig = (
    params: CAIParams,
    respondentId: string,
    assignmentId: string | undefined,
    env: string,
    loginId: string
): RespondentConfig => {
    const groupId = params.G;

    const isSandbox = params.rmeta.panelIdentifier === 'cint-sbox'

    return generateCintConfig(
        groupId,
        respondentId,
        assignmentId,
        env,
        loginId,
        {
            panelRespondentId: params.rmeta.panelRespondentID,
            sandbox: isSandbox
        }
    )
}

const generateConfig = (
    params: QueryParamsRequestable,
    loginId: string,
    generatedRespondentId: string
): RespondentConfig => {
    const env = params.env || params.G;
    const respondentId = params.RID || generatedRespondentId;
    let assignmentId = params.AID

    switch (params.__type) {
        case 'CAI_PARAMS': {
            return generateCaiConfig(
                params,
                respondentId,
                assignmentId,
                env,
                loginId
            );
        }

        case 'MTURK_PARAMS': {
            if (assignmentId === undefined) {
                assignmentId = params.assignmentId
            }
            const groupId = params.G;


            return generateMturkConfig(
                groupId,
                params.workerId,
                assignmentId,
                env,
                loginId,
                {
                    workerId: params.workerId,
                    assignmentId: params.assignmentId,
                    hitId: params.hitId,
                    completedDemographicSurvey: '',
                    isMacbook: '',
                    ...params.rmeta
                }
            )
        }

        default: assertNever(params)
    }
};

export const buildAndPushConfiguration = (
    queryStringParams: Record<string, unknown>,
    validateCintRespondent: ValidateCintRespondentRequester,
    getLoginId: GetLoginIdRequester,
    configDownloader: ConfigDownloader,
    configUploader: ConfigUploader,
): AppThunkAction<Promise<void>> => async (dispatch) => {
    const landingPageBuilder = new LandingPageConfigBuilder()
    const parsedParams = parseQueryParams(queryStringParams);

    landingPageBuilder.setParams(parsedParams)
    landingPageBuilder.setSupportEmail(LandingPageSupportEmail.help)

    if (isMturkParams(parsedParams)) {
        setupInitialMturkState(parsedParams, landingPageBuilder);
    }

    let completionCode = '00000';

    if (requiresConfiguration(parsedParams)) {
        // Validate Cint respondent.
        if (parsedParams.__type === "CAI_PARAMS" && parsedParams.rmeta.panelIdentifier === 'cint') {
            await validateCintRespondent(parsedParams.rmeta.panelRespondentID);
        }

        const loginIdParams = getLoginIdRequestParameters(parsedParams);

        let { loginId, respondentId } = await getLoginId(loginIdParams);

        const [, loginIdInt] = loginIdSplitter(loginId);
        while (respondentId.length < loginIdInt.length) {
            respondentId = "0" + respondentId
        }

        landingPageBuilder.setLoginId(loginId);

        // Check for existing config
        const existingConfig = await getConfig(configDownloader, loginId)
        let respondentConfig: RespondentConfigState
        if (existingConfig) {
            respondentConfig = existingConfig
        } else {
            respondentConfig = {
                config: generateConfig(parsedParams, loginId, respondentId),
                enabled: true
            }

            await pushConfig(configUploader, loginId, respondentConfig);
        }

        const actualCompletionCode: string | undefined = (respondentConfig.config.tasks.find(
            (t): t is RespondentCompletionCodeTask => t.type === 'completionCode'
        )?.config.completionCode);

        if (actualCompletionCode) {
            completionCode = actualCompletionCode;
        }
    } else {
        landingPageBuilder.setLoginId('-----')
    }

    switch (parsedParams.__type) {
        case 'MTURK_PARAMS':
        case 'MTURK_PREVIEW_PARAMS': {
            configureMturkPage(parsedParams, landingPageBuilder, completionCode);
            break;
        }
        case 'CAI_PARAMS': {
            handleCaiParams(parsedParams, landingPageBuilder)
            break;
        }
        default:
            assertNever(parsedParams)
    }

    const landingPageConfig = landingPageBuilder.build()
    dispatch(setupSuccess(landingPageConfig))
};

function handleCaiParams(parsedParams: CAIParams<CAIConfigKey>, landingPageBuilder: LandingPageConfigBuilder): void {
    switch (parsedParams.G) {
        case "AREM-15-MC":
        case "AREM-16-HTL":
        case "AREM-17-NTL": {
            const narrowedParams = parsedParams as CAIParams<AREMediaGroupId>
            configureAreMediaPage(landingPageBuilder, narrowedParams)
            break
        }

        case "OMDG-1A":
        case "OMDG-1B":
        case "OMDG-1C":
        case "OMDG-2A":
        case "OMDG-2B":
        case "OMDG-2C":
        case "OMDG-3A":
        case "OMDG-3B":
        case "OMDG-3C":
        case "OMDG-4":
        case "OMDG-5": {
            const narrowedParams = parsedParams as CAIParams<OMDGGroupId>
            configureOMDGPage(landingPageBuilder, narrowedParams)
            break
        }

        case "AUST-18-NEWSFEED":
        case "AUST-19-WATCH":
        case "AUST-20-STORIES":
        case "AUST-21-NEWSFEED":
        case "AUST-22-STORIES":
        case "AUST-23":
        case "AUST-24": {
            const narrowedParams = parsedParams as CAIParams<AUSTGroupId>
            configureAUSTPage(landingPageBuilder, narrowedParams)
            break
        }
        case "C9AU-27-1021-BVOD": {
            const narrowedParams = parsedParams as CAIParams<C9AU1021GroupId>
            configureC9AU1021Page(landingPageBuilder, narrowedParams)
            break
        }

        case "TWIT-30-1221-UK-TWITTER":
        case "TWIT-31-1221-UK-INSTAGRAM":
        case "TWIT-32-1221-UK-FACEBOOK":
        case "TWIT-34-1221-UK-YOUTUBE":
        case "TWIT-37-1221-UK-TWITTER-FACEBOOK":
        case "TWIT-36-1221-UK-TWITTER-INSTAGRAM":
        case "TWIT-38-1221-CAN-TWITTER":
        case "TWIT-39-1221-CAN-INSTAGRAM":
        case "TWIT-40-1221-CAN-FACEBOOK":
        case "TWIT-42-1221-CAN-YOUTUBE":
        case "TWIT-44-1221-CAN-TWITTER-INSTAGRAM":
        case "TWIT-45-1221-CAN-TWITTER-FACEBOOK":
        case "TWIT-46-1221-FRA-TWITTER":
        case "TWIT-47-1221-FRA-INSTAGRAM":
        case "TWIT-48-1221-FRA-FACEBOOK":
        case "TWIT-50-1221-FRA-YOUTUBE":
        case "TWIT-52-1221-FRA-TWITTER-INSTAGRAM":
        case "TWIT-53-1221-FRA-TWITTER-FACEBOOK":
        case "TWIT-54-1221-MEX-TWITTER":
        case "TWIT-55-1221-MEX-INSTAGRAM":
        case "TWIT-56-1221-MEX-FACEBOOK":
        case "TWIT-58-1221-MEX-YOUTUBE":
        case "TWIT-60-1221-MEX-TWITTER-INSTAGRAM":
        case "TWIT-61-1221-MEX-TWITTER-FACEBOOK":
        case "TWIT-33-1221-UK-TIKTOK":
        case "TWIT-41-1221-CAN-TIKTOK":
        case "TWIT-49-1221-FRA-TIKTOK":
        case "TWIT-57-1221-MEX-TIKTOK":
        case "TWIT-35-1221-UK-TWITTER-TIKTOK":
        case "TWIT-43-1221-CAN-TWITTER-TIKTOK":
        case "TWIT-51-1221-FRA-TWITTER-TIKTOK":
        case "TWIT-59-1221-MEX-TWITTER-TIKTOK":
            {
                const narrowedParams = parsedParams as CAIParams<TWIT1221CollGroupId>
                configureTWIT1221Page(landingPageBuilder, narrowedParams)
                break
            }

        case "REDC-64-0122-IRL-TWITTER-INFEEDVIDEO":
        case "REDC-65-0122-IRL-TWITTER-INFEEDSTATIC":
        case "REDC-66-0122-IRL-INSTAGRAM-INFEED":
        case "REDC-67-0122-IRL-INSTAGRAM-STORIES":
        case "REDC-68-0122-IRL-YOUTUBE-PREROLL":
        case "REDC-69-0122-IRL-YOUTUBE-EXTRAS":
        case "REDC-70-0122-IRL-FACEBOOK-INFEED":
        case "REDC-71-0122-IRL-FACEBOOK-STORIES":
            {
                const narrowedParams = parsedParams as CAIParams<REDC0122CollGroupId>
                configureREDC0122Page(landingPageBuilder, narrowedParams)
                break
            }

        case "YAHO-72-0422-USA-YAHOO-ITW":
        case "YAHO-73-0422-USA-SPORTS-FANTASYSPORTS-ITW":
        case "YAHO-74-0422-USA-FINANCE-ITW":
        case "YAHO-75-0422-USA-ENTERTAINMENT-ITW":
        case "YAHO-76-0422-USA-LIFESTYLE-ITW":
        case "YAHO-83-0422-CAN-YAHOO-ITW":
        case "YAHO-84-0422-CAN-SPORTS-ITW":
        case "YAHO-85-0422-CAN-STYLE-ENTERTAINMENT-ITW":
        case "YAHO-86-0422-CAN-FINANCE-ITW":
        case "YAHO-87-0422-CAN-AOL-ITW":
        case "YAHO-93-0422-AUS-LIFESTYLE-ITW":
        case "YAHO-94-0422-AUS-YAHOO-ITW":
        case "YAHO-95-0422-AUS-FINANCE-ITW":
        case "YAHO-96-0422-AUS-SPORTS-ITW":
        case "YAHO-102-0422-UK-YAHOO-ITW":
        case "YAHO-103-0422-UK-AOL-ITW":
        case "YAHO-104-0422-UK-FINANCE-ITW":
        case "YAHO-105-0422-UK-STYLE-ITW":
        case "YAHO-106-0422-UK-SPORTS-ITW":
        case "YAHO-77-0422-USA-YAHOO-EXPOSED":
        case "YAHO-78-0422-USA-SPORTS-FANTASYSPORTS-EXPOSED":
        case "YAHO-79-0422-USA-FINANCE-EXPOSED":
        case "YAHO-80-0422-USA-ENTERTAINMENT-LIFESTYLE-EXPOSED":
        case "YAHO-81-0422-USA-MSN-EXPOSED":
        case "YAHO-88-0422-CAN-YAHOO-EXPOSED":
        case "YAHO-89-0422-CAN-STYLE-CELEBRITY-EXPOSED":
        case "YAHO-90-0422-CAN-SPORTS-EXPOSED":
        case "YAHO-91-0422-CAN-FINANCE-EXPOSED":
        case "YAHO-92-0422-CAN-MSN-EXPOSED":
        case "YAHO-97-0422-AUS-YAHOO-EXPOSED":
        case "YAHO-98-0422-AUS-FINANCE-EXPOSED":
        case "YAHO-99-0422-AUS-MSN-EXPOSED":
        case "YAHO-100-0422-AUS-LIFESTYLE-EXPOSED":
        case "YAHO-101-0422-AUS-LADBIBLE-EXPOSED":
        case "YAHO-107-0422-UK-YAHOO-EXPOSED":
        case "YAHO-108-0422-UK-AOL-EXPOSED":
        case "YAHO-109-0422-UK-MSN-EXPOSED":
        case "YAHO-110-0422-UK-FINANCE-EXPOSED":
        case "YAHO-111-0422-UK-SPORTS-EXPOSED":
        case "YAHO-112-0422-AUS-SPORTS-EXPOSED":
        case "YAHO-148-0422-CAN-YAHOO-VIDEO-EXPOSED":
            {
                const narrowedParams = parsedParams as CAIParams<YAHO0422CollGroupId>
                configureYAHO0422Page(landingPageBuilder, narrowedParams)
                break
            }

        case "ODEU-113-0422-DEU-FACEBOOK-INFEED":
        case "ODEU-114-0422-DEU-FACEBOOK-INSTREAM":
        case "ODEU-115-0422-DEU-FACEBOOK-STORIES":
        case "ODEU-116-0422-DEU-YOUTUBE-PREROLL":
        case "ODEU-117-0422-DEU-YOUTUBE-BONUS":
        case "ODEU-118-0422-DEU-TWITTER-INFEED":
            {
                const narrowedParams = parsedParams as CAIParams<ODEU0422CollGroupId>
                configureODEU0422Page(landingPageBuilder, narrowedParams)
                break
            }

        case "SNAP-120-0522-UK-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-121-0522-UK-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-122-0522-UK-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-123-0522-UK-SNAPCHAT-NONBRAND-TV":
        case "SNAP-124-0522-CA-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-125-0522-CA-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-126-0522-CA-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-127-0522-CA-SNAPCHAT-NONBRAND-TV":
        case "SNAP-128-0522-US-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-129-0522-US-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-130-0522-US-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-131-0522-US-SNAPCHAT-NONBRAND-TV":
        case "SNAP-132-0522-AU-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-133-0522-AU-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-134-0522-AU-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-135-0522-AU-SNAPCHAT-NONBRAND-TV":
        case "SNAP-136-0522-DE-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-137-0522-DE-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-138-0522-DE-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-139-0522-DE-SNAPCHAT-NONBRAND-TV":
        case "SNAP-208-0522-UK-SNAPCHAT-RECOLLECT":
        case "SNAP-209-0522-CA-SNAPCHAT-RECOLLECT":
        case "SNAP-210-0522-US-SNAPCHAT-RECOLLECT":
        case "SNAP-211-0522-AU-SNAPCHAT-RECOLLECT":
        case "SNAP-212-0522-DE-SNAPCHAT-RECOLLECT":
            {
                const narrowedParams = parsedParams as CAIParams<SNAP0522CollGroupId>
                configureSNAP0522Page(landingPageBuilder, narrowedParams)
                break
            }

        case "ADCO-146-0622-UK-ADCOLONY":
            {
                const narrowedParams = parsedParams as CAIParams<ADCO0622CollGroupId>
                configureADCO0622Page(landingPageBuilder, narrowedParams)
                break
            }

        case "CRAA-152-0822-SCA-ADL-BREAKFAST":
        case "CRAA-153-0822-SCA-SYD-BREAKFAST":
        case "CRAA-154-0822-SCA-MEL-BREAKFAST":
        case "CRAA-155-0822-SCA-BNE-BREAKFAST":
        case "CRAA-156-0822-SCA-PER-BREAKFAST":
        case "CRAA-157-0822-SCA-ALL-MORNAFT":
        case "CRAA-158-0822-SCA-ALL-DRIVE":
        case "CRAA-159-0822-SCA-ALL-EVENINGS":
        case "CRAA-160-0822-SCA-PODCAST-THEBRIEFING":
        case "CRAA-161-0822-SCA-PODCAST-HAMISHANDY":
        case "CRAA-162-0822-ARN-ADL-BREAKFAST":
        case "CRAA-163-0822-ARN-SYD-BREAKFAST":
        case "CRAA-164-0822-ARN-MEL-BREAKFAST":
        case "CRAA-165-0822-ARN-BNE-BREAKFAST":
        case "CRAA-166-0822-ARN-PER-BREAKFAST":
        case "CRAA-167-0822-ARN-ALL-MORNAFT":
        case "CRAA-168-0822-ARN-ALL-DRIVE":
        case "CRAA-169-0822-ARN-ADL-EVENING":
        case "CRAA-170-0822-ARN-SYD-EVENING":
        case "CRAA-171-0822-ARN-MEL-EVENING":
        case "CRAA-172-0822-ARN-BNE-EVENING":
        case "CRAA-173-0822-ARN-PER-EVENING":
        case "CRAA-174-0822-ARN-PODCAST-IMPERFECTS":
        case "CRAA-175-0822-ARN-PODCAST-KYLEJACKIE":
        case "CRAA-176-0822-ARN-PODCAST-LIFEUNCUT":
        case "CRAA-177-0822-NINE-SYD-BREAKFAST":
        case "CRAA-178-0822-NINE-MEL-BREAKFAST":
        case "CRAA-179-0822-NINE-BNE-BREAKFAST":
        case "CRAA-180-0822-NINE-PER-BREAKFAST":
        case "CRAA-181-0822-NINE-SYD-MORNAFT":
        case "CRAA-182-0822-NINE-MEL-MORNAFT":
        case "CRAA-183-0822-NINE-BNE-MORNAFT":
        case "CRAA-184-0822-NINE-PER-MORNAFT":
        case "CRAA-185-0822-NINE-SYD-DRIVE":
        case "CRAA-186-0822-NINE-MEL-DRIVE":
        case "CRAA-187-0822-NINE-BNE-DRIVE":
        case "CRAA-188-0822-NINE-PER-DRIVE":
        case "CRAA-189-0822-NINE-SYD-EVENING":
        case "CRAA-190-0822-NINE-MEL-EVENING":
        case "CRAA-191-0822-NINE-BNE-EVENING":
        case "CRAA-192-0822-NINE-PER-EVENING":
        case "CRAA-193-0822-NINE-PODCAST-MONEYNEWS":
        case "CRAA-194-0822-NOVA-ADL-BREAKFAST":
        case "CRAA-195-0822-NOVA-SYD-BREAKFAST":
        case "CRAA-196-0822-NOVA-MEL-BREAKFAST":
        case "CRAA-197-0822-NOVA-BNE-BREAKFAST":
        case "CRAA-198-0822-NOVA-PER-BREAKFAST":
        case "CRAA-199-0822-NOVA-AMBP-MORNAFT":
        case "CRAA-200-0822-NOVA-SYD-MORNAFT":
        case "CRAA-201-0822-NOVA-ALL-DRIVE":
        case "CRAA-202-0822-NOVA-ALL-EVENING":
        case "CRAA-203-0822-NOVA-PODCAST-HENNESSYS":
        case "CRAA-204-0822-NOVA-PODCAST-KTJ":
        case "CRAA-205-0822-NOVA-PODCAST-SMALLCHANGE":
            {
                const narrowedParams = parsedParams as CAIParams<CRAA0822GroupId>
                configureCRAA0822Page(landingPageBuilder, narrowedParams)
                break
            }

        case "VIAB-149-0822-BE-NORTH-YOUTUBE":
        case "VIAB-150-0822-BE-SOUTH-YOUTUBE":
        case "VIAB-213-0822-BE-NORTH-BVOD-M-ROUND2":
        case "VIAB-214-0822-BE-SOUTH-BVOD-M-ROUND2":
            {
                const narrowedParams = parsedParams as CAIParams<VIAB0822CintGroupId>
                configureVIAB0822Page(landingPageBuilder, narrowedParams)
                break
            }

        case "ACAY-151-0822-AUS-YOUTUBE":
            {
                const narrowedParams = parsedParams as CAIParams<ACAY0822CollGroupId>
                configureACAY0822Page(landingPageBuilder, narrowedParams)
                break
            }

        case 'OMNZ-217-NZ-FACEBOOK':
        case 'OMNZ-218-NZ-INSTAGRAM':
        case 'OMNZ-219-NZ-YOUTUBE-PREROLL':
        case 'OMNZ-220-NZ-TWITTER-FEED':
        case 'OMNZ-221-NZ-TIKTOK-FEED':
        case 'OMNZ-222-NZ-STUFF-EXPOSED':
        case 'OMNZ-223-NZ-NZME-EXPOSED':
        case 'OMNZ-247-NZ-NZHERALD-RECOLLECT':
            {
                const narrowedParams = parsedParams as CAIParams<OMNZ1022CollGroupId>
                configureOMNZPage(landingPageBuilder, narrowedParams)
                break
            }

        case 'OMME-234-SAU-FACEBOOK':
        case 'OMME-235-SAU-INSTAGRAM':
        case 'OMME-236-SAU-YOUTUBE-PREROLL':
        case 'OMME-237-SAU-TWITTER-FEED':
        case 'OMME-238-SAU-TIKTOK-FEED':
        case 'OMME-239-SAU-ALMARSD-EXPOSED':
        case 'OMME-240-SAU-ALARABIYA-EXPOSED':
        case 'OMME-241-SAU-SPORT-ALMARSD-EXPOSED':
        case 'OMME-242-SAU-MOTORY-EXPOSED':
        case 'OMME-243-SAU-SO3ODY-EXPOSED':
        case 'OMME-253-UAE-FACEBOOK':
        case 'OMME-254-UAE-INSTAGRAM':
        case 'OMME-255-UAE-YOUTUBE-PREROLL':
        case 'OMME-256-UAE-TWITTER-FEED':
        case 'OMME-257-UAE-TIKTOK-FEED':
            {
                const narrowedParams = parsedParams as CAIParams<OMME1022CollGroupId>;
                configureOMMEPage(landingPageBuilder, narrowedParams);
                break;
            }

        case "NEAU-225-1022-NEWS-HERALDSUN-BASELINE":
        case "NEAU-226-1022-NEWS-HERALDSUN-FREQUENCY":
        case "NEAU-227-1022-VOGUE-AUSTRALIAN-BASELINE":
        case "NEAU-228-1022-VOGUE-AUSTRALIAN-FREQUENCY":
        case "NEAU-229-1022-KIDSPOT-ESCAPE-BASELINE":
        case "NEAU-230-1022-KIDSPOT-ESCAPE-FREQUENCY":
        case "NEAU-231-1022-TASTE-DAILYTELEGRAPH-BASELINE":
        case "NEAU-232-1022-TASTE-DAILYTELEGRAPH-FREQUENCY":
        case "NEAU-233-1022-ADELAIDENOW-COURIERMAIL-DELICIOUS-STANDARD":
            {
                const narrowedParams = parsedParams as CAIParams<NEAU1022CollGroupId>
                configureNEAU1022Page(landingPageBuilder, narrowedParams)
                break
            }

        case "TEAD-248-1222-US-PIONEER-COSMOPOLITAN-EXPOSED":
        case "TEAD-249-1222-US-TASTY-COUNTRYLIVING-EXPOSED":
        case "TEAD-250-1222-US-HOUSEBEAUTIFUL-HEALTHLINE-EXPOSED":
        case "TEAD-251-1222-US-GOODHOUSEKEEPING-THEKITCHN-EXPOSED":
        case "TEAD-252-1222-US-ESPN-DELISH-EXPOSED":
            {
                const narrowedParams = parsedParams as CAIParams<TEAD1222CollGroupId>
                configureTEAD1222Page(landingPageBuilder, narrowedParams)
                break
            }

        case "TWIC-258-1222-US-MOBILE-ITW":
        case "TWIC-259-1222-UK-MOBILE-ITW":
        case "TWIC-260-1222-AU-MOBILE-ITW":
            {
                const narrowedParams = parsedParams as CAIParams<TWIC1222CollGroupId>
                configureTWIC1222Page(landingPageBuilder, narrowedParams)
                break
            }

        case "TWIH-268-0123-US-DESKTOP-ITW":
        case "TWIH-269-0123-UK-DESKTOP-ITW":
        case "TWIH-270-0123-AU-DESKTOP-ITW":
        case "TWIH-271-0123-US-DESKTOP-INT":
        case "TWIH-272-0123-UK-DESKTOP-INT":
        case "TWIH-273-0123-AU-DESKTOP-INT":
            {
                const narrowedParams = parsedParams as CAIParams<TWIH0123CollGroupId>
                configureTWIH0123Page(landingPageBuilder, narrowedParams)
                break
            }

        case "SPOT-264-0123-AU-MUSIC":
        case "SPOT-265-0123-AU-PODCAST":
        case "SPOT-266-0123-NZ-MUSIC":
        case "SPOT-267-0123-NZ-PODCAST":
            {
                const narrowedParams = parsedParams as CAIParams<SPOT0123CollGroupId>
                configureSPOT0123Page(landingPageBuilder, narrowedParams)
                break
            }

        case "PEPS-274-0523-FACEBOOK-ESTABLISHED":
        case "PEPS-275-0523-FACEBOOK-NON":
        case "PEPS-276-0523-INSTAGRAM-ESTABLISHED":
        case "PEPS-277-0523-INSTAGRAM-NON":
        case "PEPS-278-0523-YOUTUBE-ESTABLISHED":
        case "PEPS-279-0523-YOUTUBE-NON":
        case "PEPS-280-0523-TIKTOK-ESTABLISHED":
        case "PEPS-281-0523-TIKTOK-NON":
            {
                const narrowedParams = parsedParams as CAIParams<PEPS0523CollGroupId>
                configurePEPS0523Page(landingPageBuilder, narrowedParams)
                break
            }

        case "GYGG-282-0623-NY-FACEBOOK":
        case "GYGG-283-0623-LA-FACEBOOK":
        case "GYGG-284-0623-NY-INSTAGRAM":
        case "GYGG-285-0623-LA-INSTAGRAM":
        case "GYGG-286-0623-NY-YOUTUBE":
        case "GYGG-287-0623-LA-YOUTUBE":
            {
                const narrowedParams = parsedParams as CAIParams<GYGG0623CollGroupId>
                configureGYGG0623Page(landingPageBuilder, narrowedParams)
                break
            }

        case "SPIN-288-0823-SPOTIFY":
        case "SPIN-289-0823-FACEBOOK":
        case "SPIN-290-0823-INSTAGRAM":
        case "SPIN-291-0823-YOUTUBE":
        case "SPIN-292-0823-FB-SPOT":
        case "SPIN-293-0823-IG-SPOT":
        case "SPIN-294-0823-YT-SPOT":
            {
                const narrowedParams = parsedParams as CAIParams<SPIN0823CollGroupId>
                configureSPIN0823Page(landingPageBuilder, narrowedParams)
                break
            }
        case "SNAR-295-0823-VALIDATION":
        case "SNAR-316-0823-UK-SCALED-1":
        case "SNAR-317-0823-UK-SCALED-2":
        case "SNAR-318-0823-US-SCALED-1":
        case "SNAR-319-0823-US-SCALED-2":
        case "SNAR-355-0823-FR-SCALED-1":
        case "SNAR-356-0823-FR-SCALED-2":
        case "SNAR-357-0823-SA-SCALED-1":
        case "SNAR-358-0823-SA-SCALED-2":
        case "SNAR-359-0823-CA-SCALED-1":
        case "SNAR-360-0823-CA-SCALED-2":
        case "SNAR-361-0823-CA-SCALED-3":
        case "SNAR-362-0823-AU-SCALED-1":
        case "SNAR-363-0823-AU-SCALED-2":
            {
                const narrowedParams = parsedParams as CAIParams<SNAR0823CollGroupId>
                configureSNAR0823Page(landingPageBuilder, narrowedParams)
                break
            }

        case "PINT-326-1123-US-GROUP1":
        case "PINT-327-1123-US-GROUP2":
        case "PINT-328-1123-US-GROUP3":
        case "PINT-329-1123-US-GROUP4":
        case "PINT-330-1123-UK-GROUP1":
        case "PINT-331-1123-UK-GROUP2":
        case "PINT-332-1123-UK-GROUP3":
        case "PINT-333-1123-UK-GROUP4":
        case "PINT-334-1123-AU-GROUP1":
        case "PINT-335-1123-AU-GROUP2":
        case "PINT-336-1123-AU-GROUP3":
        case "PINT-337-1123-AU-GROUP4":
        case "PINT-338-1123-FR-GROUP1":
        case "PINT-339-1123-FR-GROUP2":
        case "PINT-340-1123-FR-GROUP3":
        case "PINT-341-1123-FR-GROUP4":
        case "PINT-342-1123-DE-GROUP1":
        case "PINT-343-1123-DE-GROUP2":
        case "PINT-344-1123-DE-GROUP3":
        case "PINT-345-1123-DE-GROUP4":
        case "PINT-346-1123-CA-GROUP1":
        case "PINT-347-1123-CA-GROUP2":
        case "PINT-348-1123-CA-GROUP3":
        case "PINT-349-1123-CA-GROUP4":
            {
                const narrowedParams = parsedParams as CAIParams<PINT1123CollGroupId>
                configurePINT1123Page(landingPageBuilder, narrowedParams)
                break
            }
        case "SKYN-296-0723-BVOD-MOBILE":
            {
                configureSKYN0823Page(landingPageBuilder)
                break
            }

        case "RCTV-297-0523-BVODMOBILE":
            {
                configureRCTV0523Page(landingPageBuilder)
                break
            }

        case "YAHD-299-0823-USA-YAHOO-DESKTOP":
        case "YAHD-300-0823-USA-SPORTS-DESKTOP":
        case "YAHD-301-0823-USA-FANTASYSPORTS-DESKTOP":
        case "YAHD-302-0823-USA-ENTERTAINMENT-DESKTOP":
        case "YAHD-311-0823-AUS-YAHOO-DESKTOP":
        case "YAHD-312-0823-AUS-SPORTS-DESKTOP":
        case "YAHD-313-0823-AUS-FINANCE-DESKTOP":
        case "YAHD-314-0823-AUS-LIFESTYLE-DESKTOP":
            {
                const narrowedParams = parsedParams as CAIParams<YAHD0823DesktopGroupId>
                configureYAHD0823DesktopPage(landingPageBuilder, narrowedParams);
                break;
            }

        case "YAHD-303-0823-USA-YAHOO-MOBILE":
        case "YAHD-304-0823-USA-SPORTS-MOBILE":
        case "YAHD-305-0823-USA-FANTASYSPORTS-MOBILE":
        case "YAHD-306-0823-USA-ENTERTAINMENT-MOBILE":
        case "YAHD-307-0823-AUS-YAHOO-MOBILE":
        case "YAHD-308-0823-AUS-SPORTS-MOBILE":
        case "YAHD-309-0823-AUS-FINANCE-MOBILE":
        case "YAHD-310-0823-AUS-LIFESTYLE-MOBILE":
            {
                const narrowedParams = parsedParams as CAIParams<YAHD0823MobileGroupId>
                configureYAHD0823MobilePage(landingPageBuilder, narrowedParams);
                break;
            }

        case "GYGG-350-0124-US-YOUTUBE-1":
        case "GYGG-351-0124-US-YOUTUBE-2":
        case "GYGG-364-0124-US-FACEBOOK":
        case "GYGG-365-0124-US-INSTAGRAM":
        case "GYGG-366-0124-US-TIKTOK":
        case 'GYGG-367-0124-US-YOUTUBE-3':
        case 'GYGG-368-0124-UK-YOUTUBE-4':
        case 'GYGG-370-0124-US-YOUTUBE-5':
        case 'GYGG-371-0124-UK-YOUTUBE-6':
            {
                const narrowedParams = parsedParams as CAIParams<GYGG0124CollGroupId>
                configureGYGG0124Page(landingPageBuilder, narrowedParams);
                break;
            }

        case "SPAU-369-0324-SPOTIFY":
            {
                const narrowedParams = parsedParams as CAIParams<SPAU0324CollGroupId>
                configureSPAU0324Page(landingPageBuilder, narrowedParams)
                break
            }

        case "ALLW-372-0424-UK-LOTTO-FACEBOOK":
        case "ALLW-373-0424-UK-SETFORLIFE-FACEBOOK":
        case "ALLW-374-0424-UK-LOTTO-INSTAGRAM":
        case "ALLW-375-0424-UK-SETFORLIFE-INSTAGRAM":
        case "ALLW-376-0424-UK-LOTTO-TIKTOK":
        case "ALLW-377-0424-UK-LOTTO-YOUTUBE":
        case "ALLW-378-0424-UK-SETFORLIFE-YOUTUBE":
            {
                const narrowedParams = parsedParams as CAIParams<ALLW0424CollGroupId>
                configureALLW0424Page(landingPageBuilder, narrowedParams);
                break;
            }

        case "SNEM-379-0524-AU-SNAP-HIGH":
        case "SNEM-380-0524-AU-SNAP-MED":
        case "SNEM-381-0524-AU-SNAP-LOW":
        case "SNEM-382-0524-AU-YOUTUBE":
        case "SNEM-383-0524-AU-TIKTOK-HIGH":
        case "SNEM-384-0524-AU-TIKTOK-MED":
        case "SNEM-385-0524-AU-TIKTOK-LOW":
        case "SNEM-386-0524-AU-INSTA-HIGH":
        case "SNEM-387-0524-AU-INSTA-MED":
        case "SNEM-388-0524-AU-INSTA-LOW":
        case "SNEM-389-0524-FR-SNAP-HIGH":
        case "SNEM-390-0524-FR-SNAP-MED":
        case "SNEM-391-0524-FR-SNAP-LOW":
        case "SNEM-392-0524-FR-YOUTUBE":
        case "SNEM-393-0524-FR-TIKTOK-HIGH":
        case "SNEM-394-0524-FR-TIKTOK-MED":
        case "SNEM-395-0524-FR-TIKTOK-LOW":
        case "SNEM-396-0524-FR-INSTA-HIGH":
        case "SNEM-397-0524-FR-INSTA-MED":
        case "SNEM-398-0524-FR-INSTA-LOW":
        case "SNEM-399-0524-SA-SNAP-HIGH":
        case "SNEM-400-0524-SA-SNAP-MED":
        case "SNEM-401-0524-SA-SNAP-LOW":
        case "SNEM-402-0524-SA-YOUTUBE":
        case "SNEM-403-0524-SA-TIKTOK-HIGH":
        case "SNEM-404-0524-SA-TIKTOK-MED":
        case "SNEM-405-0524-SA-TIKTOK-LOW":
        case "SNEM-406-0524-SA-INSTA-HIGH":
        case "SNEM-407-0524-SA-INSTA-MED":
        case "SNEM-408-0524-SA-INSTA-LOW":
        case "SNEM-409-0524-UK-SNAP-HIGH":
        case "SNEM-410-0524-UK-SNAP-MED":
        case "SNEM-411-0524-UK-SNAP-LOW":
        case "SNEM-412-0524-UK-YOUTUBE":
        case "SNEM-413-0524-UK-TIKTOK-HIGH":
        case "SNEM-414-0524-UK-TIKTOK-MED":
        case "SNEM-415-0524-UK-TIKTOK-LOW":
        case "SNEM-416-0524-UK-INSTA-HIGH":
        case "SNEM-417-0524-UK-INSTA-MED":
        case "SNEM-418-0524-UK-INSTA-LOW":
        case "SNEM-419-0524-US-SNAP-HIGH":
        case "SNEM-420-0524-US-SNAP-MED":
        case "SNEM-421-0524-US-SNAP-LOW":
        case "SNEM-422-0524-US-YOUTUBE":
        case "SNEM-423-0524-US-TIKTOK-HIGH":
        case "SNEM-424-0524-US-TIKTOK-MED":
        case "SNEM-425-0524-US-TIKTOK-LOW":
        case "SNEM-426-0524-US-INSTA-HIGH":
        case "SNEM-427-0524-US-INSTA-MED":
        case "SNEM-428-0524-US-INSTA-LOW":
            {
                const narrowedParams = parsedParams as CAIParams<SNEM0524CollGroupId>
                configureSNEM0524Page(landingPageBuilder, narrowedParams);
                break;
            }

        case "GCPL-429-0624-FB-IG":
        case "GCPL-430-0624-IG-YT":
        case "GCPL-431-0624-FB-YT":
            {
                const narrowedParams = parsedParams as CAIParams<GCPL0624CollGroupId>
                configureGCPL0624Page(landingPageBuilder, narrowedParams);
                break;
            }
        case "GCPL-432-1024-IN-YOUTUBE":
            {
                const narrowedParams = parsedParams as CAIParams<GCPL1024CollGroupId>
                configureGCPL1024Page(landingPageBuilder, narrowedParams);
                break;
            }
        case "GYGG-433-1024-US-INSTAGRAM":
        case "GYGG-434-1024-US-YOUTUBE":
            {
                const narrowedParams = parsedParams as CAIParams<GYGG1024CollGroupId>
                configureGYGG1024Page(landingPageBuilder, narrowedParams);
                break;
            }
        case "OMAU-435-1124-AU-TIKTOK":
        case "OMAU-436-1124-AU-INSTAGRAM":
            {
                const narrowedParams = parsedParams as CAIParams<OMAU1124CollGroupId>
                configureOMAU1124Page(landingPageBuilder, narrowedParams);
                break;
            }
        case "VCCP-437-0225-UK-TIKTOK-GOOD":
        case "VCCP-438-0225-UK-TIKTOK-BAD":
        case "VCCP-439-0225-UK-INSTAGRAM-GOOD":
        case "VCCP-440-0225-UK-INSTAGRAM-BAD":
        case "VCCP-441-0225-UK-YAHOO-GOOD":
        case "VCCP-442-0225-UK-YAHOO-BAD":
            {
                const narrowedParams = parsedParams as CAIParams<VCCP0225CollGroupId>
                configureVCCP0225Page(landingPageBuilder, narrowedParams);
                break;
            }
        default:
            assertNever(parsedParams.G)
    }
}

const tryConfig = async (
    s3Key: string,
    configDownloader: ConfigDownloader
): Promise<RespondentConfig | undefined> => {
    try {
        return await configDownloader(s3Key)
    } catch (e: any) {
        if (e && e.code && e.code === "NoSuchKey") {
            return
        }

        // Rethrow any other error
        throw e
    }
}

export const getConfig = async (
    configDownloader: ConfigDownloader,
    loginId: string,
): Promise<RespondentConfigState | undefined> => {
    // Search for existing config for LoginID
    const [loginIdChar, loginIdInt] = loginIdSplitter(loginId)
    const s3Key = `respondentConfigs/${loginIdChar}/${loginIdInt}.json`
    const s3KeyDisabled = `respondentConfigs/${loginIdChar}/${loginIdInt}.json.deactivated`

    const config = await tryConfig(s3Key, configDownloader)
    const configDisabled = await tryConfig(s3KeyDisabled, configDownloader)

    if (config) {
        return {
            config,
            enabled: true
        }
    } else if (configDisabled) {
        return {
            config: configDisabled,
            enabled: false
        }
    }
}

export const pushConfig = async (
    configUploader: ConfigUploader,
    loginId: string,
    respondentConfig: RespondentConfigState
) => {
    // Push to S3
    try {
        const [loginIdChar, loginIdInt] = loginIdSplitter(loginId)
        const s3Key = `respondentConfigs/${loginIdChar}/${loginIdInt}.json${respondentConfig.enabled ? '' : '.deactivated'}`

        await configUploader(s3Key, respondentConfig.config)
    } catch (e) {
        throw e
    }
}
