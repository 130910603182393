import { AREMediaGroupId, AREMEDIA_GROUP_IDS } from './Respondent/ConfigDataStores/AREM';
import { AUSTGroupId, AUST_GROUP_IDS } from './Respondent/ConfigDataStores/AUST';
import { C9AU1021GroupId, C9AU1021_GROUP_IDS } from './Respondent/ConfigDataStores/C9AU-1021';
import { MturkGroupId, MTURK_GROUP_IDS } from './Respondent/ConfigDataStores/mturk';
import { ODEU0422CollGroupId, ODEU0422CollGroupIds } from './Respondent/ConfigDataStores/ODEU-0422';
import { OMDGGroupId, OMDG_GROUP_IDS } from './Respondent/ConfigDataStores/OMDG';
import { REDC0122CollGroupId, REDC0122CollGroupIds } from './Respondent/ConfigDataStores/REDC-0122';
import { TEAD1222CollGroupId, TEAD1222CollGroupIds } from "./Respondent/ConfigDataStores/TEAD-1222";
import { TWIT1221CollAllGroupIds, TWIT1221CollGroupId } from './Respondent/ConfigDataStores/TWIT-1221';
import { YAHO0422CollAllGroupIds, YAHO0422CollGroupId } from './Respondent/ConfigDataStores/YAHO-0422';
import { SNAP0522CollGroupId, SNAP0522CollGroupIds } from './Respondent/ConfigDataStores/SNAP-0522';
import { ADCO0622CollGroupId, ADCO0622CollGroupIds } from './Respondent/ConfigDataStores/ADCO-0622';
import { CRAA0822GroupId, CRAA0822CollGroupIds } from './Respondent/ConfigDataStores/CRAA-0822';
import { VIAB0822CintGroupId, VIAB0822CintGroupIds } from './Respondent/ConfigDataStores/VIAB-0822';
import { ACAY0822CollGroupId, ACAY0822CollGroupIds } from './Respondent/ConfigDataStores/ACAY-0822';
import { OMNZ1022CollGroupId, OMNZ1022CollGroupIds } from './Respondent/ConfigDataStores/OMNZ-1022';
import { NEAU1022CollGroupId, NEAU1022CollGroupIds } from './Respondent/ConfigDataStores/NEAU-1022';
import { OMME1022CollGroupId, OMME1022CollGroupIds } from './Respondent/ConfigDataStores/OMME-1022';
import { TWIC1222CollGroupId, TWIC1222CollGroupIds } from './Respondent/ConfigDataStores/TWIC-1222';
import { TWIH0123CollGroupId, TWIH0123CollGroupIds } from './Respondent/ConfigDataStores/TWIH-0123';
import { SPOT0123CollGroupId, SPOT0123CollGroupIds } from './Respondent/ConfigDataStores/SPOT-0123';
import { PEPS0523CollGroupId, PEPS0523CollGroupIds } from './Respondent/ConfigDataStores/PEPS-0523';
import { GYGG0623CollGroupId, GYGG0623CollGroupIds } from './Respondent/ConfigDataStores/GYGG-0623';
import { SPIN0823CollAllGroupIds, SPIN0823CollGroupId } from 'respondent_config_generator/src/projects/SPIN-0823';
import { SNAR0823CollGroupId, SNAR0823CollGroupIds } from 'respondent_config_generator/src/projects/SNAR-0823';
import { PINT1123CollGroupId, PINT1123CollGroupIds } from 'respondent_config_generator/src/projects/PINT-1123';
import { SKYN0823CollGroupId, SKYN0823CollGroupIds } from './Respondent/ConfigDataStores/SKYN-0823';
import { RCTV0523CollGroupId, RCTV0523CollGroupIds } from './Respondent/ConfigDataStores/RCTV-0523';
import { YAHD0823GroupId, YAHD0823DesktopGroupIds, YAHD0823MobileGroupIds } from 'respondent_config_generator/src/projects/YAHD-0823';
import { GYGG0124CollGroupId, GYGG0124CollGroupIds } from './Respondent/ConfigDataStores/GYGG-0124';
import { SPAU0324CollGroupId, SPAU0324CollGroupIds } from 'respondent_config_generator/src/projects/SPAU-0324';
import { ALLW0424CollGroupId, ALLW0424CollGroupIds } from './Respondent/ConfigDataStores/ALLW-0424';
import { SNEM0524CollGroupId, SNEM0524CollGroupIds } from './Respondent/ConfigDataStores/SNEM-0524';
import { GCPL0624CollGroupId, GCPL0624CollGroupIds } from 'respondent_config_generator/src/projects/GCPL-0624';
import { GCPL1024CollGroupId, GCPL1024CollGroupIds } from 'respondent_config_generator/src/projects/GCPL-1024';
import { GYGG1024CollGroupId, GYGG1024CollGroupIds } from 'respondent_config_generator/src/projects/GYGG-1024';
import { OMAU1124CollGroupId, OMAU1124CollGroupIds } from 'respondent_config_generator/src/projects/OMAU-1124';
import { VCCP0225CollGroupId, VCCP0225CollGroupIds } from 'respondent_config_generator/src/projects/VCCP-0225';

export const CAI_GROUP_IDS = [
    ...OMDG_GROUP_IDS,
    ...AREMEDIA_GROUP_IDS,
    ...AUST_GROUP_IDS,
    ...C9AU1021_GROUP_IDS,
    ...TWIT1221CollAllGroupIds,
    ...REDC0122CollGroupIds,
    ...YAHO0422CollAllGroupIds,
    ...ODEU0422CollGroupIds,
    ...SNAP0522CollGroupIds,
    ...ADCO0622CollGroupIds,
    ...CRAA0822CollGroupIds,
    ...VIAB0822CintGroupIds,
    ...ACAY0822CollGroupIds,
    ...OMNZ1022CollGroupIds,
    ...OMME1022CollGroupIds,
    ...NEAU1022CollGroupIds,
    ...TEAD1222CollGroupIds,
    ...TWIC1222CollGroupIds,
    ...TWIH0123CollGroupIds,
    ...SPOT0123CollGroupIds,
    ...PEPS0523CollGroupIds,
    ...GYGG0623CollGroupIds,
    ...SPIN0823CollAllGroupIds,
    ...SNAR0823CollGroupIds,
    ...PINT1123CollGroupIds,
    ...SKYN0823CollGroupIds,
    ...RCTV0523CollGroupIds,
    ...YAHD0823DesktopGroupIds,
    ...YAHD0823MobileGroupIds,
    ...GYGG0124CollGroupIds,
    ...SPAU0324CollGroupIds,
    ...ALLW0424CollGroupIds,
    ...SNEM0524CollGroupIds,
    ...GCPL0624CollGroupIds,
    ...GCPL1024CollGroupIds,
    ...GYGG1024CollGroupIds,
    ...OMAU1124CollGroupIds,
    ...VCCP0225CollGroupIds
]

/**
 * Keys which dictate the behaviour of the UI, and inform the output
 * configuration file structure.
 */
export const CONFIG_KEYS = [
    ...MTURK_GROUP_IDS,
    ...CAI_GROUP_IDS
] as const;

export type MTurkConfigKey = MturkGroupId;

export const isMTurkConfigKey = (key: string): key is MTurkConfigKey => {
    return (MTURK_GROUP_IDS as ReadonlyArray<string>).includes(key)
}

export type CAIConfigKey =
    | AREMediaGroupId
    | OMDGGroupId
    | AUSTGroupId
    | C9AU1021GroupId
    | TWIT1221CollGroupId
    | REDC0122CollGroupId
    | YAHO0422CollGroupId
    | ODEU0422CollGroupId
    | SNAP0522CollGroupId
    | ADCO0622CollGroupId
    | CRAA0822GroupId
    | ACAY0822CollGroupId
    | VIAB0822CintGroupId
    | OMNZ1022CollGroupId
    | OMME1022CollGroupId
    | NEAU1022CollGroupId
    | TEAD1222CollGroupId
    | TWIC1222CollGroupId
    | TWIH0123CollGroupId
    | SPOT0123CollGroupId
    | PEPS0523CollGroupId
    | GYGG0623CollGroupId
    | SPIN0823CollGroupId
    | SNAR0823CollGroupId
    | PINT1123CollGroupId
    | SKYN0823CollGroupId
    | RCTV0523CollGroupId
    | YAHD0823GroupId
    | GYGG0124CollGroupId
    | SPAU0324CollGroupId
    | ALLW0424CollGroupId
    | SNEM0524CollGroupId
    | GCPL0624CollGroupId
    | GCPL1024CollGroupId
    | GYGG1024CollGroupId
    | OMAU1124CollGroupId
    | VCCP0225CollGroupId

export const isCAIConfigKey = (key: string): key is CAIConfigKey => {
    return (CAI_GROUP_IDS as ReadonlyArray<string>).includes(key)
}

/**
 * Union of all supported configuration keys.
 */
export type ConfigKey =
    | MTurkConfigKey
    | CAIConfigKey;
