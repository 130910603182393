import { VCCP0225CollGroupId } from "respondent_config_generator/src/projects/VCCP-0225";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { setCurrentLang } from "../../i18n";
import { AppLanguage, getLanguageStrings } from "internationalisation";

const getPlatform = (groupId: VCCP0225CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "VCCP-437-0225-UK-TIKTOK-GOOD":
        case "VCCP-438-0225-UK-TIKTOK-BAD":
            return "tiktok";
        
        case "VCCP-439-0225-UK-INSTAGRAM-GOOD":
        case "VCCP-440-0225-UK-INSTAGRAM-BAD":
            return "instagram";
        
        case "VCCP-441-0225-UK-YAHOO-GOOD":
        case "VCCP-442-0225-UK-YAHOO-BAD":
            return "yahoo-mobile";
        
        default:
            assertNever(groupId);
    }
}

export const configureVCCP0225Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<VCCP0225CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en);

    landingPageBuilder.setSteps(
        LandingPageConfigBuilder.getStandardViewingSurveySteps(
            getPlatform(params.G)
        )
    );

    landingPageBuilder.setPageTitle(
        getLanguageStrings(AppLanguage.en).LandingPage.Title
    );
}